import React from "react";
import styled from "styled-components";
import SEO from "../../components/Seo";
import Layout from "../../components/Layout";
import { useAlternateLangs } from "../../components/Hreflangs";
import MainContent from "../../components/MainContent";
import Breadcrumb from "../../components/Breadcrumb";
import H from "../../components/Headline";
import RelatedContentCardFlexBig from "../../components/RelatedContentCardFlexBig";

const StyledMiddle = styled.div`
  h1 {
    text-align: center;
  }

  .cardcontainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }

  margin: 0vmin 1vmin;
  .container {
  }

  .related-content-card {
    max-width: 27%;
    margin-right: 0rem;
    margin-bottom: 2rem;
  }
  @media all and (max-width: 900px) {
    .related-content-card {
      max-width: 43%;
    }
  }

  @media all and (max-width: 500px) {
    .cardcontainer {
      flex-direction: column;
      align-items: center;
    }

    .related-content-card {
      max-width: 95%;
      min-width: 80%;
    }
  }
`;

const breadCrumbLevels = {
  Hem: "/se",
  Tjänster: "/se/tjänster"
};

// Hreflang data
const alternateLangs = useAlternateLangs(
  // replace with pages x-default
  "/en/services"
);

const Services = props => (
  <Layout location={props.location} alternateLangs={alternateLangs}>

    <React.Fragment>
      <SEO
        title="Frilansande Tjänster"
        lang="se"
        description="Frilansande tjänster för Analytics, SEO, Tag Management och mer. Kontakta oss för en offert!"
        alternateLangs={alternateLangs}
      />
      <MainContent maxWidth="1000">
        <StyledMiddle>
          <Breadcrumb breadCrumbLevels={breadCrumbLevels} className="breadcrumb" />
          <div className="container">
            <H as="h1">Jag kan hjälpa dig med...</H>
            <div className="cardcontainer">
              <RelatedContentCardFlexBig
                imageName="code"
                title="Analytics"
                link="/se/google-analytics-konsult"
                description="Vi kan inte förbättras om vi inte mäter vad som händer på vår webbplats."
              />
              <RelatedContentCardFlexBig
                imageName="desk"
                title="SEO"
                link="/se/seo-frilansare"
                description="Allt från en hand: On-Page SEO, teknisk och Off-Page SEO."
              />
              <RelatedContentCardFlexBig
                imageName="coworking"
                title="Tag Management"
                link="/se/google-tag-manager-konsult"
                description="Jag kan hjälpa dig att integrera och automatisera tjänster baserat på dina data."
              />
            </div>
          </div>
        </StyledMiddle>
      </MainContent>
    </React.Fragment>

  </Layout>
);

export default React.memo(Services);
